import ApiService from "@/common/api.service";

export default {
  getBalanceTransactionsByParent(parentUserId, page) {
    return ApiService.query("account-balance", {
      parent_user_id: parentUserId,
      ...page
    });
  },
  getBalanceTransaction(transactionId) {
    return ApiService.get(`account-balance/${transactionId}`);
  },
  storeBalance(transaction) {
    return ApiService.post("account-balance", transaction);
  },
  deleteBalanceTransaction(transactionId) {
    return ApiService.delete(`account-balance/${transactionId}`);
  }
};
