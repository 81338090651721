<template>
  <el-form label-width="200px" :model="markPaidForm">
    <!-- <el-form-item label="扣款方式">
      <el-checkbox
        @input="
          value => {
            if (value) {
              markPaidForm.from_user_balance = 1;
            } else {
              markPaidForm.from_user_balance = 0;
            }
          }
        "
        >從儲值餘額扣款</el-checkbox
      >
    </el-form-item> -->
    <el-form-item
      class="amount"
      required
      :label="$t('transaction.form.amount')"
    >
      <template slot="label">
        {{ $t("transaction.form.amount") }}
        <el-tooltip
          content="請填寫付款證明上顯示的金額及幣值"
          placement="top"
          popper-class="tooltipColor"
        >
          <span class="action-icon">
            <i class="fas fa-question-circle" />
          </span>
        </el-tooltip>
      </template>
      <el-row>
        <el-col :span="10">
          <InputMoney
            :allowNegativeNumber="false"
            :disabled="isOnlyUploadFileMode"
            :money="markPaidForm.price"
            :currency="markPaidForm.currency"
            @changeMoney="
              price => {
                markPaidForm.price = price;
              }
            "
            @changeCurrency="
              currency => {
                markPaidForm.currency = currency;
              }
            "
          />
          <div v-if="displayIsPaidCheckbox">
            <el-checkbox
              :true-label="1"
              :false-label="0"
              v-model="markPaidForm.isPaid"
              style="margin-right: 4px"
            />{{ $t("transaction.form.i18n1") }}
          </div>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item :label="`Account Last 5 Digits`">
      <el-input
        v-model="markPaidForm.account_last_five"
        :placeholder="`Account Last 5 Digits`"
        maxlength="5"
      ></el-input>
    </el-form-item>
    <el-form-item required :label="$t('transaction.form.date')">
      <el-date-picker
        :disabled="isOnlyUploadFileMode"
        v-model="markPaidForm.date"
        type="date"
        value-format="yyyy-MM-dd"
      />
    </el-form-item>
    <el-form-item required :label="$t('transaction.form.file')">
      <template slot="label">
        {{ $t("transaction.form.file") }}
        <el-tooltip
          content="匯款單截圖、手寫收據拍照等"
          placement="top"
          popper-class="tooltipColor"
        >
          <span class="action-icon">
            <i class="fas fa-question-circle" />
          </span>
        </el-tooltip>
      </template>
      <el-upload
        :action="imageAction"
        :headers="token"
        :data="{ type: 'bill' }"
        :on-success="handleUploadSuccess"
        :on-remove="handleUploadRemove"
        :file-list="fileList"
        name="document"
      >
        <el-button size="small" type="primary">
          {{ $t("teacherDetail.upload") }}
        </el-button>
      </el-upload>
    </el-form-item>
    <el-form-item :label="$t('transaction.form.remark')" required>
      <el-input
        type="textarea"
        :rows="2"
        v-model="markPaidForm.remark"
        :placeholder="notePlaceholder"
        :disabled="isOnlyUploadFileMode"
      />
    </el-form-item>
    <el-form-item>
      <el-row type="flex" justify="center">
        <el-col :span="4">
          <el-button type="primary" @click="handleOk">
            {{ submitButtonText }}
          </el-button>
        </el-col>
      </el-row>
    </el-form-item>
  </el-form>
</template>

<script>
import moment from "moment";
import "@/assets/scss/style.scss";
import JwtService from "@/common/jwt.service";
import toeflApi from "@/apis/toefl";
import { InputMoney } from "@/components/input";

export default {
  components: {
    InputMoney
  },
  props: {
    displayIsPaidCheckbox: {
      type: Boolean,
      default: false
    },
    isOnlyUploadFileMode: {
      type: Boolean,
      default: false
    },
    submitButtonText: {
      type: String,
      default: "標記已付"
    },
    price: {
      type: Number,
      default: 0
    },
    currency: {
      type: String || null,
      default: null
    },
    remark: {
      type: String || null,
      default: ""
    },
    date: {
      type: String || null,
      default: null
    },
    notePlaceholder: {
      type: String,
      default: ""
    },
    account_last_five: {
      type: String,
      default: null
    }
  },
  watch: {
    price: {
      immediate: true,
      handler(price) {
        this.markPaidForm.price = this.price;
      }
    },
    currency: {
      immediate: true,
      handler(currency) {
        this.markPaidForm.currency = this.currency;
      }
    },
    remark: {
      immediate: true,
      handler(remark) {
        if (!this.remark) return;
        this.markPaidForm.remark = this.remark;
      }
    },
    date: {
      immediate: true,
      handler(date) {
        if (!this.date) return;
        this.markPaidForm.date = this.date;
      }
    }
  },
  computed: {
    imageAction() {
      return `${process.env.VUE_APP_BACKEND_URL}document`;
    },
    token() {
      return { Authorization: `Bearer ${JwtService.getToken()}` };
    }
  },
  data() {
    return {
      markPaidForm: {
        isPaid: 1,
        price: 0,
        currency: null,
        date: moment().format("YYYY-MM-DD"),
        remark: "",
        file: "",
        from_user_balance: 0,
        account_last_five: ""
      },
      uploadFileId: "",
      fileList: []
    };
  },
  methods: {
    handleOk() {
      let errorMessage = null;
      if (!this.markPaidForm.price) {
        errorMessage = "請輸入交易金額";
      }
      if (!this.markPaidForm.file) {
        errorMessage = "請上傳付款證明";
      }
      if (!this.markPaidForm.date) {
        errorMessage = "請輸入交易日期";
      }
      if (errorMessage) {
        this.$message({
          showClose: true,
          message: errorMessage,
          type: "error"
        });
        return;
      }
      this.$emit("handleOk", {
        ...this.markPaidForm,
        price: Number(this.markPaidForm.price)
      });
    },
    handleUploadSuccess(response, file, fileList) {
      if (this.uploadFileId) {
        this.handleUploadRemove(file, fileList);
      } else {
        this.uploadFileId = response.document.id;
        this.fileList = fileList;
      }
      this.markPaidForm.file = response.document.url;
    },
    async handleUploadRemove(file, fileList) {
      await this.$store.dispatch("bill/deleteUploadFile", this.uploadFileId);
      if (fileList.length > 1) {
        this.fileList = fileList.slice(1, 2);
      } else {
        this.fileList = [];
        this.uploadFileId = "";
      }
    }
  }
};
</script>
<style scoped>
.amount > ::v-deep .el-form-item__label {
  margin-top: 6px;
}
</style>
