<template>
  <div style="display: flex; flex-direction: column;">
    <el-input
      :disabled="disabled"
      :value="money"
      @input="value => {
        if (String(value)[0] === '0' && 1 < String(value).length) {
          $emit('changeMoney', value.slice(1));
        } else {
          $emit('changeMoney', value);
        }
      }"
      class="input-with-select"
    >
      <el-button slot="prepend">
        <span v-if="currency === 'CNY'">¥</span>
        <span v-else>$</span>
      </el-button>
      <template slot="append">
        <CurrencySelector
          :disabled="disabledCurrencySelector || disabled"
          :customStyle="{ width: '80px' }"
          :value="currency"
          @change="
            value => {
              $emit('changeCurrency', value.id);
            }
          "
        />
      </template>
    </el-input>
    <span v-if="warningText" style="color: red">* 請輸入大於等於 0 的金額</span>
  </div>
</template>

<script>
import { CurrencySelector } from "@/components/selector";

export default {
  props: {
    money: {
      type: [String, Number],
      default: "0"
    },
    currency: {
      type: String,
      default: "TWD"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledCurrencySelector: {
      type: Boolean,
      default: false
    },
    allowNegativeNumber: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CurrencySelector
  },
  data() {
    return {
      warningText: false
    };
  },
  methods: {
    isNumber(value) {
      return !Number.isNaN(Number(value));
    }
  }
};
</script>

<style scoped>
.el-input {
  width: 100%
}
::v-deep .el-input-group__prepend {
  padding: 5px;
}
::v-deep .el-input__inner {
  padding: 10px;
}
</style>
