<template>
  <div class="body container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{ name: 'ProfileForAdmin', params: { id: userId } }"
          >
            {{ userName }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link
            :to="{ name: 'Balance', query: { username: $route.query.parent } }"
          >
            {{ $t("pageTitle.Balance") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>Add</el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h1>{{ $t("balance.form.pleaseFinishStoreBalanceForm") }}</h1>
    <hr class="separate-line" />
    <el-form label-width="200px">
      <el-form-item :label="$t('balance.form.transactionType')">
        <el-select
          style="width: 100%"
          :value="transaction.category"
          @change="category => setTransaction({ category })"
        >
          <el-option :label="$t('balance.form.income')" value="income" />
          <el-option :label="$t('balance.form.expense')" value="expense" />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('balance.form.paymentType')">
        <el-select
          style="width: 100%"
          :value="transaction.paymentIndex"
          @change="paymentIndex => setTransaction({ paymentIndex })"
        >
          <el-option
            v-for="tutoringPayment in tutoringPayments"
            :key="tutoringPayment.index"
            :label="
              `${tutoringPayment.title} ${
                tutoringPayment.account_number
                  ? `(${tutoringPayment.account_number})`
                  : ``
              }`
            "
            :value="`${tutoringPayment.index}`"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <TransactionForm
      :price="transaction.originalPrice"
      :currency="transaction.originalCurrency"
      :date="transaction.date"
      :remark="transaction.remark"
      :account_last_five="transaction.account_last_five"
      :submitButtonText="$t('balance.form.storeAmount')"
      @handleOk="addBalance"
    />
  </div>
</template>

<script>
import { MoneyCalculator } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import TransactionForm from "@/components/bill/TransactionForm";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import profileApi from "@/apis/profile";
import billApi from "@/apis/bill";
import balanceApi from "@/apis/balance";

class BalanceTransaction {
  constructor({
    category,
    originalCurrency,
    originalPrice,
    date,
    remark,
    parentUserId,
    paymentIndex,
    file,
    editor,
    account_last_five
  } = {}) {
    this.category = category || "income";
    this.originalCurrency = originalCurrency || "TWD";
    this.originalPrice = Number(originalPrice) || 0;
    const now = new Date();
    const dateYYYYMMDD = `${now.getFullYear()}-${(
      now.getMonth() +
      1 +
      ""
    ).padStart(2, "0")}-${(now.getDate() + "").padStart(2, "0")}`;
    this.date = date || dateYYYYMMDD;
    this.remark = remark || "";
    this.parentUserId = parentUserId || null;
    this.paymentIndex = paymentIndex || "9";
    this.file = file || "";
    this.editor = editor || "";
    this.account_last_five = account_last_five || "";
  }
}

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.Balance")} - Ivy-Way Academy`
    };
  },
  components: {
    TransactionForm,
    Breadcrumb
  },
  mixins: [roleMixin, moneyMixin],
  computed: {
    userName() {
      if (!this.basicInfo) return "";
      return `${this.basicInfo.first_name} ${this.basicInfo.last_name}`;
    },
    userId() {
      if (!this.basicInfo) return "";
      return this.basicInfo.id;
    }
  },
  data() {
    return {
      basicInfo: null,
      tutoringPayments: [],
      transaction: {}
    };
  },
  async created() {
    this.tutoringPayments = this.filterBalance(
      await billApi.getTutoringPayments()
    );
    const { basic_info: basicInfo } = await profileApi.getUserByName(
      this.$route.query.parent
    );
    this.basicInfo = basicInfo;
    this.transaction = new BalanceTransaction({
      parentUserId: this.basicInfo.id
    });
  },
  methods: {
    filterBalance(tutoringPayments) {
      return tutoringPayments.filter(({ title }) => title !== "儲值餘額");
    },
    setTransaction(transaction) {
      this.transaction = new BalanceTransaction({
        ...this.transaction,
        ...transaction
      });
    },
    async addBalance(transaction) {
      this.setTransaction({
        ...transaction,
        originalPrice: transaction.price,
        originalCurrency: transaction.currency
      });

      const exchangeRate = await this.$store.dispatch(
        "currency/fetchExchangeRate"
      );
      const moneyCalculator = new MoneyCalculator(exchangeRate);
      const postBalance = {
        original_currency: this.transaction.originalCurrency,
        original_price: this.transaction.originalPrice,
        price: moneyCalculator.convertPriceByCurrency(
          this.transaction.originalPrice,
          this.transaction.originalCurrency,
          "TWD"
        ),
        payment_index: this.transaction.paymentIndex,
        parent_user_id: this.transaction.parentUserId,
        datetime: `${this.transaction.date} 00:00:00`,
        file: this.transaction.file,
        remark: this.transaction.remark,
        category: this.transaction.category,
        editor: this.transaction.editor,
        account_last_five: this.transaction.account_last_five
      };
      try {
        await balanceApi.storeBalance(postBalance);
        this.$router.push({
          name: "Balance",
          query: { username: this.$route.query.parent }
        });
        this.$message.success(this.$t("message.create_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style scoped>
.summary {
  padding: 28px 0;
  border: 2px solid #e2e2e2;
  margin: 10px auto;
  text-align: center;
}

.summary h2 {
  color: #42a16a;
}

.summary a {
  text-decoration: none;
}
</style>
