var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('el-input',{staticClass:"input-with-select",attrs:{"disabled":_vm.disabled,"value":_vm.money},on:{"input":value => {
      if (String(value)[0] === '0' && 1 < String(value).length) {
        _vm.$emit('changeMoney', value.slice(1));
      } else {
        _vm.$emit('changeMoney', value);
      }
    }}},[_c('el-button',{attrs:{"slot":"prepend"},slot:"prepend"},[(_vm.currency === 'CNY')?_c('span',[_vm._v("¥")]):_c('span',[_vm._v("$")])]),_c('template',{slot:"append"},[_c('CurrencySelector',{attrs:{"disabled":_vm.disabledCurrencySelector || _vm.disabled,"customStyle":{ width: '80px' },"value":_vm.currency},on:{"change":value => {
            _vm.$emit('changeCurrency', value.id);
          }}})],1)],2),(_vm.warningText)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("* 請輸入大於等於 0 的金額")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }